import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext';
import { ComboBox, TextInput, Button, ToastNotification, FileUploader, ProgressBar } from '@carbon/react';
import { read, utils, write } from 'xlsx'; 
import fetchDataUsers from '../../components/TerenTable/fetchDataUsers';
import fetchDataPlans from '../../components/TerenTable/fetchDataPlans';
import './CSS/TerenImport.scss';

const TerenImport = () => {
  const { pb } = useAuth();
  
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isCreatingPlan, setIsCreatingPlan] = useState(false);
  const [newPlanName, setNewPlanName] = useState('');
  const [newPlanDate, setNewPlanDate] = useState(null);

  const [files, setFiles] = useState(null);
  const [importMessage, setImportMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  const [totalToImport, setTotalToImport] = useState(0);
  const [importedCount, setImportedCount] = useState(0);
  const [isImporting, setIsImporting] = useState(false);

  const [failedRows, setFailedRows] = useState([]);

  // Pridobivanje agentov
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const userResult = await fetchDataUsers(pb);
        setAgents(userResult.data);
      } catch (error) {
        console.error('Napaka pri pridobivanju agentov:', error);
      }
    };
    fetchAgents();
  }, [pb]);

  // Pridobivanje planov, ko je izbran agent
  useEffect(() => {
    const fetchAgentPlans = async () => {
      if (selectedAgent) {
        try {
          const planResult = await fetchDataPlans(pb, selectedAgent.id);
          setPlans(planResult.data);
        } catch (error) {
          console.error('Napaka pri pridobivanju planov:', error);
          setPlans([]);
        }
      } else {
        setPlans([]);
      }
      setSelectedPlan(null);
    };
    fetchAgentPlans();
  }, [selectedAgent, pb]);

  // Ustvarjanje novega plana
  const handleCreatePlan = async () => {
    if (newPlanName.trim() && selectedAgent) {
      const data = {
        agent: selectedAgent.id,
        ime: newPlanName,
        planiran_datum: newPlanDate ? new Date(newPlanDate).toISOString() : null,
      };
      try {
        const newPlan = await pb.collection('plan').create(data);
        setPlans(prevPlans => [...prevPlans, newPlan]);
        setSelectedPlan(newPlan);
        setIsCreatingPlan(false);
        setNewPlanName('');
        setNewPlanDate(null);
      } catch (error) {
        console.error('Napaka pri ustvarjanju plana:', error);
        setImportMessage('Napaka pri ustvarjanju plana: ' + error.message);
        setShowToast(true);
      }
    }
  };

  // Obdelava datotek
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    try {
      const data = await file.arrayBuffer();
      const workbook = read(data);
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 }); 
      const headers = jsonData.shift(); 
      const records = jsonData.map(row => {
        const record = {};
        headers.forEach((header, index) => {
          record[header] = row[index];
        });
        return record;
      });
      setFiles(records);
    } catch (error) {
      console.error('Napaka pri obdelavi datoteke:', error);
      setImportMessage('Napaka pri obdelavi datoteke: ' + error.message);
      setShowToast(true);
    }
  };

  // Funkcija za prenos neuspešnih vrstic
  const downloadFailedRows = () => {
    if (failedRows.length === 0) return;

    const headers = Object.keys(failedRows[0]).concat(['error']);
    const worksheet = utils.json_to_sheet(failedRows, { header: headers });
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Not imported');
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'neuspesne_vrstice.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Uvoz terena na izbran plan
  const importTerenToPlan = async () => {
    if (!selectedPlan || !files) {
      setImportMessage('Izberite plan in datoteko za uvoz.');
      setShowToast(true);
      return;
    }

    setIsImporting(true);
    setTotalToImport(files.length);
    setImportedCount(0);
    setImportMessage('');
    setFailedRows([]);
    let numImported = 0;
    let tempMessages = [];

    for (let record of files) {
      try {
        const resultList = await pb.collection('Teren').getList(1, 500, {
          filter: `postni_okolis_sifra = "${record.postni_okolis_sifra}" && ulica_naziv = "${record.ulica_naziv}" && hs_stevilka = "${record.hs_stevilka}"`,
        });

        if (resultList.items.length === 0) {
          throw new Error('Naslov ni najden na GURS.');
        }

        for (let terenRecord of resultList.items) {
          const newRecordData = {
            plan: selectedPlan.id,
            teren: terenRecord.id,
          };
          await pb.collection('plan_teren').create(newRecordData);
          numImported++;
          tempMessages.push(`Vnos ${numImported}: Uspešno dodan teren ${terenRecord.id}`);
        }
      } catch (error) {
        console.error('Napaka pri uvozu terena:', error);
        tempMessages.push(`Napaka pri uvozu terena: ${error.message}`);

        setFailedRows(prev => [...prev, { ...record, error: error.message }]);
      }
      setImportedCount(prev => prev + 1);
    }

    setImportMessage(`Uvoz zaključen. Število uvoženih terenov: ${numImported}`);
    setShowToast(true);
    setFiles(null);
    setIsImporting(false);
  };

  return (
    <div className="plan-import-app">
      <h2>Uvoz Terenov na Plan</h2>
      <br />

      <div className="section">
        <label>Izberi Agenta:</label>
        <ComboBox
          items={agents}
          itemToString={(item) => (item ? item.name : '')}
          selectedItem={selectedAgent}
          onChange={({ selectedItem }) => setSelectedAgent(selectedItem)}
          placeholder="Izberi agenta"
        />
      </div>

      <div className="section">
        <label>Izberi Plan:</label>
        <ComboBox
          items={plans}
          itemToString={(item) => (item ? item.ime : '')}
          selectedItem={selectedPlan}
          onChange={({ selectedItem }) => setSelectedPlan(selectedItem)}
          placeholder="Izberi plan"
        />
        <br/>
        <Button onClick={() => setIsCreatingPlan(!isCreatingPlan)}>
          {isCreatingPlan ? 'Prekliči' : 'Ustvari nov plan'}
        </Button>
        {isCreatingPlan && (
          <div className="create-plan">
            <TextInput
              labelText="Ime Plana"
              value={newPlanName}
              onChange={(e) => setNewPlanName(e.target.value)}
              placeholder="Vnesi ime plana"
            />
            <TextInput
              labelText="Datum Plana"
              type="date"
              value={newPlanDate || ''}
              onChange={(e) => setNewPlanDate(e.target.value)}
            />
            <Button onClick={handleCreatePlan}>Shrani Plan</Button>
          </div>
        )}
      </div>

      <div className="section">
        <label>Uvozi Excel Datoteko:</label>
        <FileUploader
          labelDescription="Naloži svojo Excel datoteko"
          buttonLabel="Dodaj datoteko"
          accept={['.xlsx', '.xls']}
          onChange={handleFileChange}
        />
        <Button onClick={importTerenToPlan} disabled={!selectedPlan || !files || isImporting}>
          Uvozi Teren
        </Button>
      </div>

      {isImporting && (
        <div className="section">
          <label>Napredek uvoza:</label>
          <ProgressBar
            value={importedCount}
            max={totalToImport}
            className="import-progress-bar"
            description={`${importedCount} od ${totalToImport} uvoženih`}
          />
        </div>
      )}

      {failedRows.length > 0 && (
        <div className="section">
          <Button onClick={downloadFailedRows} kind="danger" size="md">
            Prenesi Neuspešne Vrstice
          </Button>
        </div>
      )}

      {showToast && (
        <ToastNotification
          kind="success"
          title="Uvoz Terenov"
          subtitle={importMessage}
          onClose={() => setShowToast(false)}
          timeout={7000}
        />
      )}
    </div>
  );
};

export default TerenImport;